import { Component, OnInit, ViewChild } from '@angular/core';
import { navItems } from '../../_nav';
import { DialogComponent } from './../../components/dialog/dialog.component';
import { AuthenticationService } from './../../_services/authentication.service';
import { CheckRoleService } from './../../_services/checkRole.service';
import { UserIdleService } from 'angular-user-idle';
import { ConfirmDialogErrorComponent } from '../../components/confirm-dialog-error/confirm-dialog-error.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as Constanst from '../../_utils/contanst';
import { Role } from 'src/app/_models/role';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  @ViewChild(DialogComponent, { static: false }) dialog: DialogComponent;
  public sidebarMinimized = false;
  public navItems = navItems;
  currentUser: any;
  listSideBar: any = [];
  bsModalRef: BsModalRef;
  checkFirstAutoLogout: boolean = false;
  useRole: string;
  ROLE = Constanst.ROLE;
  urlLogo: string;
  companyHelp;
  constructor(
    private authenticationService: AuthenticationService,
    private checkRole: CheckRoleService,
    private userIdle: UserIdleService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.useRole = JSON.parse(localStorage.getItem("currentUser"))?.roleName;
    this.authenticationService.currentUser.subscribe((currentUser) => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.userIdle.resetTimer();
      }
    });
    this.initDataSideBar();
    this.autoLogoutWhenUserIdle();
    this.getCompanyLogoUrlByRole(JSON.parse(localStorage.getItem("currentUser")));
    this.companyHelp = JSON.parse(localStorage.getItem("companyHelp"));
  }

  initDataSideBar() {
    let cUser = this.checkRole.currentUser;
  
    navItems.forEach((navItem) => {
      const isRoleMatch = navItem.role.indexOf(cUser.roleName) > -1;
      const isMakerOrMBS = cUser.roleName === Role.Maker || cUser.roleName === Role.MBS;
      const isSekisuiCheckPassed = isMakerOrMBS || !navItem.checkSekisui || cUser.isSekisui == 1;
  
      if (isRoleMatch && isSekisuiCheckPassed) {
        this.listSideBar.push(navItem);
      }
    });
  }

  getCompanyLogoUrlByRole(data: any) {
    const dataCompany = data?.company
    let urlLogo: string = "";
    switch (this.useRole) {
      case this.ROLE.AGENCY:
      case this.ROLE.DIVISION:
        urlLogo = dataCompany?.company.company_logo
        break;
      case this.ROLE.COMPANY:
        urlLogo = dataCompany?.company_logo
        break;
      case this.ROLE.MBS:
      case this.ROLE.MAKER:
        urlLogo = data?.logoMBSMaker;
        break;
      case this.ROLE.OFFICE:
        urlLogo = dataCompany?.division?.company?.company_logo
        break;
    }
    this.urlLogo = urlLogo;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logOut() {
    this.dialog.show(
      'ログアウトしてもよろしいですか？',
      'default',
      'ログアウト',
      'キャンセル'
    );
  }

  handleLogOut({ action }) {
    if (action === 'OK') {
      this.callApiLogout();
    }
  }

  callApiLogout() {
    this.authenticationService.logout().subscribe(
      (res) => {
        this.authenticationService.handleAfterLogout();
      },
      (rej) => {
        console.log(rej);
      }
    );
  }

  autoLogoutWhenUserIdle() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe((count) => console.log(count));
    this.userIdle.onTimeout().subscribe(() => this.showDialogConfirm());
  }

  showDialogConfirm() {
    if (!this.checkFirstAutoLogout) {
      window.location.reload();
      this.bsModalRef = this.modalService.show(ConfirmDialogErrorComponent, {
        class: 'modal-dialog-primary',
      });
      this.bsModalRef.content.message =
        '操作がない状態で一定時間が経過したため自動的にログアウトしました。';
      this.bsModalRef.content.closeBtnName = '閉じる';
      this.checkFirstAutoLogout = true;
      this.callApiLogout();
      this.userIdle.stopTimer();
    }
  }

  downloadPdf(value) {
    window.open(value, '_blank');
  }
}
