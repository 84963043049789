export const ROLE = {
    MBS: 'MBS',
    COMPANY: 'COMPANY',
    DIVISION: 'DIVISION',
    OFFICE: 'OFFICE',
    AGENCY: 'AGENCY',
    MAKER: 'MAKER',
  };
  
  export const ORDER_STATUS = {
    INPUTING: {
      CODE: '00',
      NAME: '注文入力中',
    },
    ORDERED: {
      CODE: '01',
      NAME: '注文提出済',
    },
    CONFIRMED: {
      CODE: '02',
      NAME: '注文確定',
    },
    DELETED: {
      CODE: '03',
      NAME: '削除済',
    },
    SHIPPED: {
      CODE: '04',
      NAME: '出荷済み',
    },
  };
  
  export const FORMATTER = {
    DATE: 'yyyy/MM/dd',
  };
  
  export const IS_SEKISUI = '1';
  export const IS_CURTAIN = '1';
  export const REQUEST_REPORT = '004';
  
  export const OPTIONS_TYPE = {
    REQUIRED: '1',
    NOT_REQUIRED: '0',
  };
  
  export const ORDER_TYPE = {
    EXTERNAL: '0',
    INTERNAL: '1',
  };
  
  export const DELIVERY_TYPE = {
    COMPANY: '0',
    PROPERTY: '1',
  };
  
  export const VALUE = {
    STRING_EMPTY: '',
    DATE_EMPTY: '----/--/--',
  };
  
  export const MODEL = {
    TYPE: {
      DEFAULT: 'default',
      SUCCESS: 'success',
      ERROR: 'error',
      WARNING: 'warning',
      ORDER_INPUTING: 'order_inputing',
    },
    ACTION: {
      OK: 'OK',
      CANCEL: 'cancel',
    },
  };
  
  export const ADMINISTRATIVE_STATUS = {
    INPUTTING: '入力中',
    REQUESTING: '依頼中',
    NOTE_AVAILABLE: '対応不可',
    CONFIRMED: '確認済み',
    COMPLETED: '対応済み',
    REMAND: '差し戻し',
    PAYMENT_NOT_ALLOWED: '支払NG',
    PAYMENT_TARGET: '支払対象',
  };
  
  export const ADMINISTRATIVE_STATUS_ALL = {
    INPUTTING: [
      {
        label: '入力中',
        value: '入力中',
      },
    ],
    REQUESTING: [
      {
        label: '依頼中',
        value: '依頼中',
      },
    ],
    NOTE_AVAILABLE: [
      {
        label: '対応不可',
        value: '対応不可',
      },
    ],
    CONFIRMED: [
      {
        label: '確認済み',
        value: '確認済み',
      },
    ],
    COMPLETED: [
      {
        label: '対応済み',
        value: '対応済み',
      },
    ],
    REMAND: [
      {
        label: '差し戻し',
        value: '差し戻し',
      },
    ],
    PAYMENT_NOT_ALLOWED: [
      {
        label: '支払NG',
        value: '支払NG',
      },
    ],
    PAYMENT_TARGET: [
      {
        label: '支払対象',
        value: '支払対象',
      },
    ],
  };
  
  export const REQUEST_ITEM = [
    {
      label: '写真',
      value: '写真',
    },
    {
      label: '設え',
      value: '設え',
    },
    {
      label: '写真・設え',
      value: '写真・設え',
    },
  ];
  
  export const RW_PHOTO_REGISTERED = [
    {
      label: '',
      value: '',
    },
    {
      label: '〇',
      value: '1',
    },
  ];
  
  export const MANAGEMENT_STATUS = {
    INPUTING: {
      CODE: '00',
      NAME: '入力中',
    },
    REQUEST: {
      CODE: '01',
      NAME: '依頼中',
    },
    NOT_AVAILABLE: {
      CODE: '02',
      NAME: '対応不可',
    },
    CONFIRMED: {
      CODE: '03',
      NAME: '確認済み',
    },
    ACCEPTED: {
      CODE: '04',
      NAME: '対応済み',
    },
    RETURN: {
      CODE: '05',
      NAME: '差し戻し',
    },
    PAYMENT_NOT_ALLOW: {
      CODE: '06',
      NAME: '支払NG',
    },
    PAYMENT_TARGET: {
      CODE: '07',
      NAME: '支払対象',
    },
  };
